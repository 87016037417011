.section-pt {
  padding: 5%;
  background-color: whitesmoke;
  color: black;
}
.content-pt {
  text-align: left;
  padding: 1% 5%;
  background-color: white;
  border-radius: 1%;
}
