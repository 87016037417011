li {
  float: left;
  /* padding: 30px; */
}

nav ul {
  list-style-type: none;
  color: white;
  background: black;
  overflow: hidden;
}
li a {
  text-decoration: none;
  color: white;
}

.active {
  background-color: rgb(157, 162, 158);
}

#menu_icon_li {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
#menu_li,
#footer_li {
  text-align: right;
  display: flex;
  float: right;
}

.menu,
.footer-item {
  padding-inline: 20px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

nav {
  width: 100%;
}

.menu_mobile {
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .menu {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  #menu_icon_li {
    display: none;
  }
}
.clear {
  clear: both;
  height: 120px;
}
footer {
  background-color: #191c21;
  padding-top: 0.008%;
  padding-bottom: 0.008%;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  position: relative;
}

footer ul {
  list-style-type: none;
  color: white;
  background: #191c21;
  overflow: hidden;
}

.whatsapp_float {
  border-radius: 40%;
  padding: 0.5%;
  background-color: #25d366;
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: white;
  text-align: center;
  font-size: 35px !important;
  z-index: 100;
  animation: blink-animation 3s infinite;
  &:hover {
    animation-play-state: paused;
  }
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    padding: 1.5%;
    bottom: 20px;
    right: 10px;
    font-size: 30px !important;
    animation: blink-animation 3s infinite;
    &:hover {
      animation-play-state: paused;
    }
  }
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
