.container {
  position: relative;
  text-align: center;
  margin-top: -1%;
}

/* Centered text */
.centered {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  text-align: center;
  color: white;
}
.section {
  text-align: center;
  padding: 2%;
}
.section-course {
  background-color: #191c21;
  color: white;
}
.section-team {
  background-color: white;
  color: #191c21;
}
.card-container {
  display: flex;
  justify-content: center;
}
/* Float four columns side by side */
.column {
  float: left;
  width: 25%;
  margin: 1%;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 0 -5px;
  display: contents;
  padding: 10%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 700px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .review .column {
    width: 100% !important;
  }
  #header {
    font-size: 0.8em;
  }
  .row {
    display: block;
  }
  .intro_img {
    width: 100%;
    height: 300px;
  }
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
  color: black;
  border-radius: 5px;
}
.card-team {
  background-color: black;
  color: white;
  padding: 10%;
}

a {
  text-decoration: none;
}
a :hover {
  color: rgb(208, 208, 217);
}
.course-img {
  transition: transform 0.2s;
}
.course-img:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(0.95);
  cursor: pointer;
}
.course-img {
  aspect-ratio: 3/1.7;
}
.double-quotes {
  content: "\201C";
}
.review p {
  text-align: left;
  align-items: start;
  text-align: left;
  color: #5b5f67;
  font-family: monospace;
  margin-top: 0;
}
.review table {
  cursor: pointer;
  width: 100%;
}
.review small {
  color: #5b5f67;
}
.review .card {
  text-align: start;
}
.review .column {
  width: 30%;
}
.review td {
  text-align: left;
}
